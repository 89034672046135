import { Tooltip, Menu, Modal } from 'antd';
import http_config from 'common/config/http_config';
import Logo from 'common/image/logo.jpeg';
import { useAuthInfo } from 'features/auth/hooks/useAuthInfo';
import React, { Children, useEffect, useState } from 'react';
import { FiActivity, FiCommand, FiUsers, FiVolume2, FiFile } from 'react-icons/fi';
import { FaBook } from 'react-icons/fa';
import { SiGoogleclassroom } from 'react-icons/si';
import { BiSupport, BiDevices } from 'react-icons/bi';
import { IoNewspaperOutline } from 'react-icons/io5';
import {
  LogoutOutlined,
  BarChartOutlined,
  DotChartOutlined,
  MailOutlined,
} from '@ant-design/icons';

import useLogin from 'features/auth/hooks/useLogin';
import useSchoolConfig from 'features/schoolConfig/hooks/useSchoolConfig';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { useDashboard } from 'features/dashboard/hooks/useDashboard';
import { SidebarItemWrapper, SidebarMenu, SidebarMenuItem, SidebarWrapper } from './sidebar.style';
import RoleHandle from '../Role';
import DigitalClock from '../DigitalClock';
import { Label } from 'features/users/view/user.style';

interface Props {}

const Sidebar: React.FC<Props> = () => {
  const user = useAuthInfo();
  const { configData, fetchSchoolConfig } = useSchoolConfig();
  const { onLogout } = useLogin();
  const { fetchCurrentAcademicYear } = useDashboard();
  const currentAcademicYear = useSelector((state: RootState) => state.academicYearActive);

  useEffect(() => {
    fetchCurrentAcademicYear();
    if (configData) return;
    fetchSchoolConfig();
    // eslint-disable-next-line
  }, []);

  const logout = (): void => {
    Modal.confirm({
      icon: <LogoutOutlined />,
      title: 'Log out!',
      content: 'Are you sure?',
      cancelText: 'No, cancel!',
      okText: 'Yes, log out.',
      okType: 'danger',
      onOk: onLogout,
      centered: true,
      maskClosable: true,
    });
  };

  return (
    <>
      <SidebarWrapper>
        <header>
          <img src={Logo} alt="" />
          <p>{configData?.data?.schoolDetails?.schoolName}</p>
        </header>

        <RoleHandle />

        <DigitalClock />

        <section className="academic-year">
          <p>{currentAcademicYear?.data?.year || '----'}</p>
          <small>Academic Year</small>
        </section>

        <section className="user-details">
          <aside className="user-details-left">
            <img src={user.image || http_config.DEFAULT_IMAGE} alt="" />
            <div className="details">
              <p className="name">{user.roles[0].role_description}</p>
              <p className="email">{user.email}</p>
            </div>
          </aside>
          <aside className="user-details-right">
            <Tooltip title="Log Out">
              <span className="user-logout" onClick={logout}>
                <LogoutOutlined />
              </span>
            </Tooltip>
          </aside>
        </section>

        <>
          <SidebarMenu style={{ width: '100%', padding: '0 10px' }} mode="inline">
            <SidebarMenuItem style={{ padding: 0, paddingLeft: 0 }} key="dashboard">
              <SidebarItem goto="/dashboard" icon={<FiCommand />} title="Dashboard" />
            </SidebarMenuItem>

            {/* 2 users */}
            <Menu.ItemGroup key="users" title="Users">
              <SidebarMenuItem>
                <SidebarItem goto="/users/ids" icon={<FiUsers />} title="ID Cards" />
              </SidebarMenuItem>

              <SidebarMenuItem>
                <SidebarItem goto="/users/students" icon={<FiUsers />} title="Students" />
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarItem goto="/users/teachers" icon={<FiUsers />} title="Teachers" />
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarItem goto="/users/parents" icon={<FiUsers />} title="Parents" />
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarItem goto="/users/other-staffs" icon={<FiUsers />} title="Other Staffs" />
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarItem goto="/users/role" icon={<FiUsers />} title="Users Role" />
              </SidebarMenuItem>
            </Menu.ItemGroup>

            {/* 3 Attendances */}
            <Menu.ItemGroup key="attendance" title="Attendances">
              <SidebarMenuItem>
                <SidebarItem goto="/attendance" icon={<BarChartOutlined />} title="Students" />
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarItem goto="/attendance/staff" icon={<DotChartOutlined />} title="Staff" />
              </SidebarMenuItem>
              {/* <SidebarMenuItem>
                <SidebarItem
                  goto="/attendance/requests"
                  icon={<IoNewspaperOutline />}
                  title="Requests"
                />
              </SidebarMenuItem> */}
            </Menu.ItemGroup>
            {/* 3 Attendances */}
            <Menu.ItemGroup key="attendance" title="Upgrade Students">
              <SidebarMenuItem>
                <SidebarItem
                  goto="/upgrade-students"
                  icon={<BarChartOutlined />}
                  title="Upgrade Students"
                />
              </SidebarMenuItem>
            </Menu.ItemGroup>

            {/* support */}
            <Menu.ItemGroup key="supports" title="Support">
              <SidebarMenuItem>
                <SidebarItem goto="/attendance/support" icon={<BiSupport />} title="Tickets" />
              </SidebarMenuItem>
            </Menu.ItemGroup>

            {/* 3.5 Announcements */}
            <Menu.ItemGroup key="announcements" title="Announcements">
              <SidebarMenuItem>
                <SidebarItem goto="/announcement" icon={<FiVolume2 />} title="Announcement" />
              </SidebarMenuItem>
            </Menu.ItemGroup>

            {/* 4 Calendar */}
            <Menu.ItemGroup key="calendar" title="Calendar">
              <SidebarMenuItem>
                <SidebarItem goto="/events" icon={<SiGoogleclassroom />} title="Events" />
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarItem goto="/holiday" icon={<SiGoogleclassroom />} title="Holiday" />
              </SidebarMenuItem>
              {/* <SidebarMenuItem>
                <SidebarItem goto="/exam-plan" icon={<FaBook />} title="Exam Plan" />
              </SidebarMenuItem> */}
            </Menu.ItemGroup>

            {/* 4.5 Class Routine */}
            <Menu.ItemGroup key="routines" title="Routines">
              <SidebarMenuItem>
                <SidebarItem goto="/routine" icon={<FaBook />} title="Class Routine" />
              </SidebarMenuItem>
            </Menu.ItemGroup>

            {/* 5 Exams */}
            {/* <Menu.ItemGroup key="exams" title="Exams">
              <SidebarMenuItem>
                <SidebarItem goto="/examRoutine" icon={<SiGoogleclassroom />} title="Routines" />
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarItem
                  goto="/exam-year"
                  icon={<SiGoogleclassroom />}
                  title="Exam Year & Type"
                />
              </SidebarMenuItem>
            </Menu.ItemGroup> */}

            {/* 5.1 Results */}
            {/* <Menu.ItemGroup key="results" title="Results">
              <SidebarMenuItem>
                <SidebarItem goto="/result/test" icon={<FiActivity />} title="Test Results" />
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarItem
                  goto="/result/terminal"
                  icon={<FiActivity />}
                  title="Terminal Results"
                />
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarItem goto="/result/final" icon={<FiActivity />} title="Final Results" />
              </SidebarMenuItem>
            </Menu.ItemGroup> */}

            {/* 6 Insert First */}
            <Menu.ItemGroup key="insert-first" title="School Info">
              <SidebarMenuItem>
                <SidebarItem goto="/class" icon={<SiGoogleclassroom />} title="Class" />
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarItem goto="/section" icon={<SiGoogleclassroom />} title="Section" />
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarItem goto="/subject" icon={<FaBook />} title="Subject" />
              </SidebarMenuItem>

              <SidebarMenuItem>
                <SidebarItem
                  goto="/academic-year"
                  icon={<SiGoogleclassroom />}
                  title="Academic Year"
                />
              </SidebarMenuItem>
            </Menu.ItemGroup>

            {/* Library */}
            {/* <Menu.ItemGroup key="library" title="Library">
              <SidebarMenuItem>
                <SidebarItem goto="/book" icon={<FaBook />} title="Book" />
              </SidebarMenuItem>
            </Menu.ItemGroup> */}

            {/* Devices */}
            {/* <Menu.ItemGroup key="devices" title="Devices">
              <SidebarMenuItem>
                <SidebarItem goto="/devices" icon={<BiDevices />} title="List Devices" />
              </SidebarMenuItem>
            </Menu.ItemGroup> */}

            {/* <Menu.ItemGroup key="actions" title="Actions">
              <SidebarMenuItem>
                <SidebarItem
                  goto="/leave-application"
                  icon={<FiFile />}
                  title="leave-application"
                />
              </SidebarMenuItem>
            </Menu.ItemGroup> */}
            <Menu.ItemGroup key="actions" title="Actions">
              <SidebarMenuItem>
                <SidebarItem goto="/bulk-codes" icon={<FiFile />} title="User Card Id Download" />
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarItem goto="/bulk-assign" icon={<FiFile />} title="User Card Id Assign" />
              </SidebarMenuItem>
            </Menu.ItemGroup>
            {/* <Menu.ItemGroup key="bulk" title="Bulk Actions">
              <SidebarMenuItem>
                <SidebarItem goto="/bulk-codes" icon={<FiFile />} title="bulk-codes" />
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarItem goto="/bulk-assign" icon={<FiFile />} title="bulk-assign" />
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarItem goto="/bulk-upload" icon={<FiFile />} title="bulk-upload" />
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarItem goto="/bulk-subjects" icon={<FiFile />} title="bulk-subjects" />
              </SidebarMenuItem>
            </Menu.ItemGroup> */}
            {/* others */}

            {/* end */}
          </SidebarMenu>
        </>
      </SidebarWrapper>
    </>
  );
};

interface SidebarItemType {
  icon: any;
  title: string;
  goto: string;
}

const SidebarItem = ({ icon, title, goto }: SidebarItemType) => {
  return (
    <SidebarItemWrapper to={goto} exact activeClassName="active">
      <aside className="left">{icon}</aside>
      <aside className="right">{title}</aside>
    </SidebarItemWrapper>
  );
};

export default Sidebar;
