import React, { useState } from 'react';
import { Form, Button, Input, Select, DatePicker, Radio } from 'antd';
import { Option } from 'antd/lib/mentions';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import TextEditor from 'common/component/Inputs/TextEditor';
import { useSection } from 'features/routine/hooks/useSection';
import { useHoliday } from '../hooks/useHoliday';
import { AddHolidayWrapper } from './holiday.style';
import Calendar from 'common/component/KCalendar';
import '@sbmdkl/nepali-datepicker-reactjs/dist/index.css';

export const AddHoliday = (props: any) => {
  const { onSaveHoliday } = useHoliday();
  const { fetchSections } = useSection();
  const [disabledSelectSections, setDisabledSelectSections] = useState(false);
  const [forAll, setForAll] = useState(false);
  const [form] = Form.useForm();
  const { onVisibleChange, onSetReloadHoliday } = props;
  const section = useSelector((state: RootState) => state.section);
  const [description, setDescription] = React.useState<string>('');
  const [date, setDate] = React.useState<string>(props?.date);

  const onDescriptionChange = (value: string): void => {
    setDescription(value);
  };

  const handleSubmit = () => {
    onSaveHoliday(
      {
        ...form.getFieldsValue(),
        forAll,
        description,
        date,
      },
      form,
      onVisibleChange,
      onSetReloadHoliday,
    );
  };

  React.useEffect(() => {
    fetchSections();
  }, []);
  React.useEffect(() => {
    setDisabledSelectSections(forAll);
  }, [forAll]);

  return (
    <AddHolidayWrapper>
      <Form form={form} size="large" layout="vertical">
        <Form.Item name="title" label="Name">
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <div className="quill__field">
            <TextEditor onChange={onDescriptionChange} value={description} />
          </div>
        </Form.Item>
        <Form.Item name="date" label="Date">
          <DatePicker
            className="grade-select-field"
            onChange={(date, dateString): void => setDate(dateString)}
          />
          {/* <Calendar
            style={{ width: 250 }}
            language="en"
            placeholder="Select Date of Issue"
            onChange={({ adDate }: any) => {
              setDate(new Date(adDate).toISOString());
            }}
          /> */}
        </Form.Item>
        <Form.Item name="forAll" label="For All">
          <Radio
            checked={forAll}
            value={forAll}
            onClick={() => {
              setForAll((prev) => !prev);
            }}
          >
            For All
          </Radio>
        </Form.Item>
        {!disabledSelectSections && (
          <Form.Item name="sectionIds" label="Sections">
            <Select
              mode="multiple"
              size="middle"
              placeholder="Please select"
              value={section.data.map((item) => item.id)}
              style={{ width: '100%' }}
            >
              {section.data.map((item: any) => (
                <Option key={item.id}>{item.id}</Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item>
          <Button htmlType="submit" onClick={handleSubmit}>
            SAVE
          </Button>
        </Form.Item>
      </Form>
    </AddHolidayWrapper>
  );
};

export default AddHoliday;
