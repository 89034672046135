import KSpinner from 'common/component/KSpinner';
import HomeView from 'features/home/view/HomeView';
import favIcon from 'common/image/favicon.png';
import useSchoolConfig from 'features/schoolConfig/hooks/useSchoolConfig';
import decodeApiMessage from 'common/helper/decodeApiMessage';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { notifyError } from 'common/helper/notifications';
import { onRefresh } from 'data/auth/auth.service';
import { useAuthInfo } from './features/auth/hooks/useAuthInfo';
import LoginView from './features/auth/view/LoginView';
import systemSlice from './redux/slice/system.slice';

const loaderStyles = {
  width: '100vw',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

function App(): JSX.Element {
  const system = useAuthInfo();
  const dispatch = useDispatch();
  const { configData, fetchSchoolConfig, isLoading } = useSchoolConfig();
  const [authStatus, setAuthStatus] = React.useState<'loading' | 'idle' | 'success'>('idle');

  React.useEffect(() => {
    const fromStorageToState = (): void => {
      const userDetails = localStorage.getItem('user_details');
      const rl = localStorage.getItem('activeRole');
      if (userDetails) {
        const userDetailsJson = JSON.parse(userDetails);
        const activeRole = rl ? JSON.parse(rl) : userDetailsJson?.__roles__[0];

        if (!rl) localStorage.setItem('activeRole', JSON.stringify(activeRole));

        dispatch(
          systemSlice.actions.setState({
            ...userDetailsJson,
            roles: userDetailsJson?.__roles__ || [],
            isLogged: true,
            activeRole,
          }),
        );
      }
    };

    const refresh = async (): Promise<void> => {
      setAuthStatus('loading');
      try {
        fromStorageToState();
      } catch (error) {
        dispatch(systemSlice.actions.resetState());
        notifyError('Authentication', decodeApiMessage(error));
        localStorage.clear();
      } finally {
        setAuthStatus('success');
      }
    };

    // fetchSchoolConfig();
    const userId = localStorage.getItem('userId');
    if (userId) refresh();
  }, [dispatch]);

  const loading = React.useMemo(() => {
    return isLoading || authStatus === 'loading';
  }, [isLoading, authStatus]);

  React.useEffect(() => {
    fetchSchoolConfig();
  }, []);

  return (
    <>
      <Helmet>
        <title>{configData?.data?.schoolDetails?.schoolName}</title>
        <link rel="icon" href={favIcon} />
        {/* <script
          src="@sajanm/nepali-date-picker/dist/nepali.datepicker.v4.0.1.min.js"
          type="text/javascript"
        ></script> */}
      </Helmet>

      {/* while school config is loading */}
      {loading ? (
        <div style={loaderStyles}>
          <KSpinner />
        </div>
      ) : null}

      {/* while school config has loaded */}
      {!loading ? (
        <>
          <div>
            {!system.isLogged && (
              <Switch>
                <Route path="/" component={LoginView} />
              </Switch>
            )}
            {system.isLogged && <HomeView />}
          </div>
        </>
      ) : null}
    </>
  );
}

export default App;
