import KPageHeader from 'common/component/KPageHeader';
import KProTip from 'common/component/KProTip';
import { tableCustomStyles } from 'common/style/tableCustomStyle';
import React, { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import { Button, Col, Drawer, Popconfirm, Row } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import RichTextView from 'common/component/RichTextView';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import AddHoliday from './AddHoliday';
import EditHoliday from './EditHoliday';
import { useHoliday } from '../hooks/useHoliday';
// import NepaliDate from 'nepali-date-converter';
import { addNumberToMonth } from 'features/result/utils/utils';

const { convertToBS } = require('nepali-date-converter/dist/lib/nepali-date-helper');
interface Props {}

const HolidayView: React.FC<Props> = () => {
  const [visible, setVisible] = React.useState(false);
  const holidays = useSelector((state: RootState) => state.holiday);
  const [reloadHoliday, setReloadHoliday] = React.useState(true);
  const [showEditDrawer, setShowEditDrawer] = React.useState(false);
  const [selectedHoliday, setSelectedHoliday] = React.useState<any>(null);
  const { fetchHoliday, onDeleteHoliday } = useHoliday();

  const handleDelete = async (id: string) => {
    await onDeleteHoliday(id, setShowEditDrawer, setReloadHoliday);
  };

  const TableColumn = () => [
    {
      name: 'Holiday',
      selector: 'title',
      sortable: true,
    },
    {
      name: 'Description',
      cell: (row: any) => (
        <div>
          <RichTextView value={row.description} />
        </div>
      ),
    },
    {
      name: 'Images',
      selector: 'images',
      sortable: true,
    },
    {
      name: 'Date',
      selector: 'date',
      sortable: true,
      cell: (row: any) => {
        const date = new Date(row.date);

        // const newDate = moment(date)
        //   .add(1, 'months')
        //   .format('YYYY-MM-DD');

        const newData = convertToBS(new Date(date));

        return (
          <div>
            {newData.BS.year}-{addNumberToMonth(newData.BS.month)}-{newData.BS.date}
          </div>
        );
      },
    },
    {
      name: 'Sections',
      selector: 'sections',
    },
    {
      name: 'For All',
      selector: 'forAll',
    },
    {
      name: 'Actions',
      selector: 'actions',
      sortable: true,
      cell: (row: any) => (
        <Row gutter={16}>
          <Col>
            <Button
              onClick={() => {
                setSelectedHoliday(row);
                setShowEditDrawer(true);
              }}
            >
              <EditFilled />
            </Button>
          </Col>
          <Col>
            <Popconfirm
              // placement="topRight"
              title="Are you sure?"
              onConfirm={() => handleDelete(row.id)}
              okText="Yes"
              cancelText="No"
            >
              <Button>
                <DeleteFilled />
              </Button>
            </Popconfirm>
          </Col>
        </Row>
      ),
    },
  ];

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const memoHolidayReload = useMemo(() => reloadHoliday, [reloadHoliday]);

  React.useEffect(() => {
    if (memoHolidayReload) {
      fetchHoliday();
      setReloadHoliday(false);
      setSelectedHoliday(null);
    }
  }, [memoHolidayReload]);

  return (
    <HolidayViewWrapper>
      <KPageHeader title="Holiday" rightButtonTitle="Add Holiday" onRightButtonClick={showDrawer} />
      <Drawer
        width={780}
        title={<h2 style={{ fontWeight: 700 }}>Edit Holiday</h2>}
        placement="right"
        onClose={() => {
          setSelectedHoliday(null);
          setShowEditDrawer(false);
        }}
        visible={showEditDrawer}
        closable={false}
      >
        {selectedHoliday && (
          <EditHoliday
            // eslint-disable-next-line react/jsx-props-no-spreading
            // {...selectedHoliday}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...selectedHoliday}
            // id={selectedHoliday.id}
            // // title={selectedHoliday.title}
            // description={selectedHoliday.description}
            // images={selectedHoliday.images}
            // date={selectedHoliday.date}
            onVisibleChange={setShowEditDrawer}
            onSetReloadHoliday={setReloadHoliday}
          />
        )}
      </Drawer>
      <Drawer
        width={780}
        title={<h2 style={{ fontWeight: 700 }}>Add Holiday</h2>}
        placement="right"
        onClose={onClose}
        visible={visible}
        closable={false}
      >
        <AddHoliday onVisibleChange={setVisible} onSetReloadHoliday={setReloadHoliday} />
      </Drawer>
      <KProTip
        title="Page Hint"
        description="This page is for viewing and managing all the holidays that are currently in this system"
      />
      <section className="dataTable">
        <DataTable
          customStyles={tableCustomStyles}
          className="data-table"
          noHeader
          highlightOnHover
          pagination
          responsive={true}
          data={holidays.data.map((item) => ({
            id: item.id,
            title: item.title,
            date: item.date,
            description: item.description,
            forAll: item.forAll ? 1 : 0,
            sections: item.sections.map((_) => _.id).join(', '),
            // grades: item.grades.map((_) => _.id).join(', '),
          }))}
          columns={TableColumn()}
        />
      </section>
    </HolidayViewWrapper>
  );
};

export const HolidayViewWrapper = styled.div`
  padding-right: 20px;
  .dataTable {
    margin-top: 20px;
  }
`;

// const HolidayView = () => <h1>Hellop</h1>;

export default HolidayView;
