import AnnouncementCalendar from '../../../common/component/KCalendar';
import React, { useState } from 'react';
import { Button, DatePicker, Form, Input, Radio, Select } from 'antd';
import TextEditor from 'common/component/Inputs/TextEditor';
import { AddAnnouncementFormWrapper } from './announcement.style';
import { useAnnouncement } from '../hooks/useAnnouncement';
import Calendar from 'common/component/KCalendar';
import '@sbmdkl/nepali-datepicker-reactjs/dist/index.css';

export const AnnouncementForm = (props: any): any => {
  const [form] = Form.useForm();
  const { onVisibleChange, onReload, category } = props;
  const { onSaveAnnouncement } = useAnnouncement();

  const [description, setDescription] = React.useState<string>('');
  const [date, setDate] = React.useState<string>(props?.date);

  const onDescriptionChange = (value: string): void => {
    setDescription(value);
  };

  const handleSubmit = (): void => {
    onSaveAnnouncement(
      { ...form.getFieldsValue(), description, sendNow, date },
      form,
      onVisibleChange,
      onReload,
    );
  };
  const [sendNow, setSendNow] = useState(false);
  const [disabledSendNow, setDisabledSendNow] = useState(false);

  React.useEffect(() => {
    setDisabledSendNow(sendNow);
  }, [sendNow]);

  return (
    <AddAnnouncementFormWrapper>
      <Form form={form} layout="vertical" size="large">
        <Form.Item label="Category" name={['category', 'title']}>
          <Select defaultValue=" Select Category">
            {category.map((item: any) => (
              <Select.Option value={item.title}>{item.title}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Title" name="title">
          <Input />
        </Form.Item>
        <Form.Item label="Description">
          <div className="quill__field">
            <TextEditor onChange={onDescriptionChange} value={description} />
          </div>
        </Form.Item>
        {!disabledSendNow && (
          <Form.Item name="date" label="Date">
            {/* <Calendar
              style={{ width: 250 }}
              language="en"
              placeholder="Select Date of Issue"
              onChange={({ adDate }: any) => {
                setDate(new Date(adDate).toISOString());
              }}
            /> */}
            <DatePicker
              className="grade-select-field"
              onChange={(date, dateString): void => setDate(dateString)}
            />
          </Form.Item>
        )}
        <Form.Item name="sendNow" label="Send Now">
          <Radio
            checked={sendNow}
            value={sendNow}
            onClick={() => {
              setSendNow((prev) => !prev);
            }}
          >
            Send Now
          </Radio>
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" onClick={handleSubmit}>
            SAVE
          </Button>
        </Form.Item>
      </Form>
    </AddAnnouncementFormWrapper>
  );
};

export default AnnouncementForm;
