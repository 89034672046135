import moment from 'moment';
import { ResultType, Subject } from '../data';
// import NepaliDate from 'nepali-date-converter';
const { convertToBS } = require('nepali-date-converter/dist/lib/nepali-date-helper');

export const getRangeDescAndRemarks = (percentage: number) => {
  if (percentage >= 80)
    return {
      rangeDesc: 'Distinction',
      remarks: `Outstanding. We're proud of your results.`,
    };
  if (percentage > 60)
    return {
      rangeDesc: 'FIRST DIVISION',
      remarks: `Good. Keep up the good work.`,
    };
  if (percentage > 50)
    return {
      rangeDesc: 'SECOND DIVISION',
      remarks: `Ok but you should strive for better results.`,
    };
  if (percentage > 40)
    return {
      rangeDesc: 'THIRD DIVISION',
      remarks: `Focus on better grades next time.`,
    };

  return {
    rangeDesc: 'FAIL',
    remarks: `Labour Hard.`,
  };
};

function sort_by_id() {
  return function(elem1: any, elem2: any) {
    if (elem1.id < elem2.id) {
      return -1;
    } else if (elem1.id > elem2.id) {
      return 1;
    } else {
      return 0;
    }
  };
}

function sort_by_subjectid() {
  return function(elem1: any, elem2: any) {
    if (elem1.subjectId < elem2.subjectId) {
      return -1;
    } else if (elem1.subjectId > elem2.subjectId) {
      return 1;
    } else {
      return 0;
    }
  };
}

export const sortedSubjects = (subjects: Subject[]) => {
  return subjects;
  return subjects.sort(sort_by_id());
};

//sorting the subjects object of student object for comparing
export const sortedSubjectsOfStudents = (subjects: any) => {
  return subjects?.sort(sort_by_subjectid());
};

interface ReturnType {
  id: string;
  studentName: string;
  subjects: Array<{
    subjectId: string;
    title: string;
    full_mark: string;
    pass_mark: string;
    obtained_mark: string;
  }>;
}
export const compareAndCombine = (subjects: Subject[], results: ResultType[]) => {
  const finalResults = results?.map((result) => {
    subjects = sortedSubjects(subjects);
    const resultWithData = {
      id: result?.id,
      studentName: result?.studentName,
      studentRoll: result.studentRoll,
      position: result?.position,
      percentage: result?.percentage,
      remarks: result?.remarks,
      overAllGpa: result?.overAllGpa,
      totalFM: result?.totalFM,
      totalOM: result?.totalOM,
    };
    let data = subjects.map((sub) => {
      const tempResult = result?.subjects?.find((subject) => subject.subjectId === sub.id);
      if (tempResult != null)
        return {
          subjectId: sub.id,
          title: sub.title,
          full_mark: sub.full_mark,
          pass_mark: sub.pass_mark,
          obtained_mark: tempResult.obtained_mark,
          gpa: tempResult.gpa,
        };
    });
    return { ...resultWithData, subjects: data };
  });
  return finalResults;
};

export const nf = Intl.NumberFormat('en-US', {
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

// interface NewDate extends Date {
//   addDays(days: any): any;
// }

// const days = { Sun: 0, Mon: 1, Tue: 2, Wed: 3, Thu: 4, Fri: 5, Sat: 6 };

export const addNumberToMonth = (month: number) => {
  const currentMonth = month;

  const newMonth = currentMonth + 1;

  if (newMonth > 12) {
    return 1;
  }

  return newMonth;

  // if (month < 10) {
  //     return `0${month + 1}`;
  // }
  // return month;
};

// export const subtractNumberToDate = (date: number) => {

// }

// export function getNepaliDays(firstDay: any, lastDay: any) {
//   console.log({ firstDay, lastDay });
//   let rangeDates = getDates(new Date(firstDay), new Date(lastDay));
//   let nepaliDays = rangeDates.map((date) => {
//     const convertedDate = convertToBS(new Date(date));

//     const formattedDate = `${convertedDate.BS.year}-${addNumberToMonth(convertedDate.BS.month)}-${
//       convertedDate.BS.date
//     }`;

//     return { nepali: formattedDate, english: moment(date).format('YYYY-MM-DD') };
//   });
//   return nepaliDays;
// }

// export function getDays(firstDay: any, lastDay: any) {
//   let rangeDates = getDates(new Date(firstDay), new Date(lastDay));
//   return rangeDates;
// }

// export function getDates(startDate: any, stopDate: any) {
//   var dateArray = new Array();
//   var currentDate = new Date(startDate) as NewDate;
//   while (currentDate <= stopDate) {
//     dateArray.push(new Date(currentDate));
//     currentDate = currentDate.addDays(1);
//   }
//   return dateArray;
// }

// (Date as any).prototype.addDays = function(days: any) {
//   var date = new Date(this.valueOf());
//   date.setDate(date.getDate() + days);
//   return date;
// };

// console.log(findClassDays('2019-12-01', '2019-12-15'));

// function adToBs(ad: any) {
//   const bs = new NepaliDate(ad).getBS();
//   return `${bs.year}-${bs.month}-${bs.date}`;
// }

// export const getBSDate = (adDate: string) => {
//   const newDate = convertToBS(new Date(adDate));

//   return `${newDate.BS.year}-${addNumberToMonth(newDate.BS.month)}-${newDate.BS.date}`;
// };

export function getDates(startDateIn: string, stopDateIn: string) {
  var dateArray = [];
  var currentDate = moment(startDateIn);
  var stopDate = moment(stopDateIn);
  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).format('YYYY-MM-DD'));
    currentDate = moment(currentDate).add(1, 'days');
  }
  return dateArray;
}
