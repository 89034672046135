// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { DatePicker, Popover } from 'antd';
import { KButton } from 'common/component/KButton';
import KPageHeader from 'common/component/KPageHeader';
import KProTip from 'common/component/KProTip';
import KSpinner from 'common/component/KSpinner';
import { tableCustomStyles } from 'common/style/tableCustomStyle';
import React from 'react';
import DataTable from 'react-data-table-component';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import styled from 'styled-components';
import SelectClass from 'common/component/Inputs/SelectClass';
import SelectSection from 'common/component/Inputs/SelectClassSection';
import SelectMonth from 'common/component/Inputs/SelectMonth';
import { useAttendance } from '../hooks/useAttendance';
import tableColumn from './user.table';
import { useGrade } from '../../routine/hooks/useGrade';
import Calendar from 'common/component/KCalendar';
// import '@sbmdkl/nepali-datepicker-reactjs/dist/index.css';

interface Props {}

const AttendanceView: React.FC<Props> = () => {
  const [selectedGrade, setSelectedGrade] = React.useState<string>('');
  const [selectedSection, setSelectedSection] = React.useState<string>('');
  const [tableColumns, setTableColumns] = React.useState<any>();
  //   const [selectedMonth, setSelectedMonth] = React.useState<string>('');
  //   const [selectedYear, setSelectedYear] = React.useState<string>('');
  const [isGetAttendanceIsClicked, setIsGetAttendanceIsClicked] = React.useState<boolean>(false);
  //   const [date, setDate] = React.useState<string>('');
  const attendance = useSelector((state: RootState) => state.attendance);

  const [from, setFrom] = React.useState<string>('');
  const [to, setTo] = React.useState<string>('');

  const { fetchGrade } = useGrade();
  const { fetchAttendanceByRange } = useAttendance();

  const handleGetAttendance = (): void => {
    setTableColumns(tableColumn(from, to));
    fetchAttendanceByRange(selectedGrade, selectedSection, from.split('T')[0], to.split('T')[0]);
    setIsGetAttendanceIsClicked(true);
  };

  React.useEffect(() => {
    fetchGrade();
  }, []);
  return (
    <AttendanceViewWrapper>
      <KPageHeader title="Attendance" />
      <KProTip
        title="Page Hint"
        description="This page is for viewing and managing attendance of students"
      />
      {/* <span style={{ color: 'red', fontStyle: 'italic' }}>
        Note: Whole months attendance will be shown no matter the date
      </span> */}
      <section className="section-wrapper">
        <section className="page-tabs">
          <SelectClass
            onChange={(value): void => {
              setSelectedGrade(value);
              setIsGetAttendanceIsClicked(false);
            }}
          />
        </section>
        <section className="page-tabs">
          <SelectSection
            gradeId={selectedGrade}
            onChange={(val): void => {
              setSelectedSection(val);
              setIsGetAttendanceIsClicked(false);
            }}
          />
        </section>

        <section
          className="page-tabs"
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 4 }}
        >
          From:
          <DatePicker
            className="grade-select-field"
            onChange={(date, dateString): void => setFrom(dateString)}
          />
          {/* <Calendar
            style={{ width: 250 }}
            // dateFormat="YYYY"
            theme="green"
            language="en"
            onChange={({ adDate }: any) => {
              setFrom(new Date(adDate).toISOString());
            }}
            placeholder="Select Date of Issue"
          /> */}
        </section>

        <section
          className="page-tabs"
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 4 }}
        >
          To:
          <DatePicker
            className="grade-select-field"
            onChange={(date, dateString): void => setTo(dateString)}
          />
          {/* <Calendar
            style={{ width: 250 }}
            // dateFormat="YYYY"
            theme="green"
            language="en"
            onChange={({ adDate }: any) => {
              setTo(new Date(adDate).toISOString());
            }}
            placeholder="Select Date of Issue"
          /> */}
        </section>

        <KButton onClick={handleGetAttendance}>Get Attendance</KButton>
      </section>

      {isGetAttendanceIsClicked && (
        <section className="page-table">
          {attendance.status === 'loading' && <KSpinner />}
          {attendance.status === 'data' && (
            <DataTable
              customStyles={tableCustomStyles}
              className="data-table"
              noHeader
              highlightOnHover
              dense
              responsive
              data={attendance.data}
              columns={tableColumns}
            />
          )}
        </section>
      )}
    </AttendanceViewWrapper>
  );
};

export const AttendanceViewWrapper = styled.div`
  padding-right: 20px;

  .page-table {
    /* width: 80%; */
  }

  .page-tabs {
    margin: 16px 0;
    display: flex;
    justify-content: space-evenly;
    .grade-select-field {
      min-width: 200px;
    }
  }

  .section-wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
`;

export default AttendanceView;
