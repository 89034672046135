import { RootState } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { SchoolConfigResponseModel } from 'data/config/school/school.model';
import schoolConfigSlice from 'redux/slice/schoolConfig.slice';
import { notifyError } from 'common/helper/notifications';
import decodeApiMessage from 'common/helper/decodeApiMessage';
import schoolService from 'data/config/school/school.service';

interface UseSchoolConfigReturns {
  configData: SchoolConfigResponseModel;
  isLoading: boolean;
  fetchSchoolConfig: () => Promise<void>;
}

const useSchoolConfig = (): UseSchoolConfigReturns => {
  const schoolConfig = useSelector((state: RootState) => state.schoolConfig);
  const dispatch = useDispatch();

  const fetchSchoolConfig = async (): Promise<void> => {
    try {
      dispatch(schoolConfigSlice.actions.setLoading());
      const cfg = await schoolService.onGetSchoolConfig();
      dispatch(schoolConfigSlice.actions.setData(cfg));
    } catch (error) {
      dispatch(schoolConfigSlice.actions.setError(error));
      //   notifyError('Accessing School Config', decodeApiMessage(error));
    }
  };

  return {
    configData: schoolConfig.data,
    isLoading: schoolConfig.status === 'loading',
    fetchSchoolConfig,
  };
};

export default useSchoolConfig;
