import React from 'react';

import { Button, Card, Divider, Modal } from 'antd';
import { Holiday } from 'data/holiday/holiday.modle';
import { useHoliday } from 'features/holiday/hooks/useHoliday';
import DataTable, { TableColumn } from 'react-data-table-component';
import KSpinner from 'common/component/KSpinner';
import { ReloadOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import moment from 'moment';

import { AbsentViewWrapper } from '../dashboard.style';

const upcomingHolidaysTableModel = (): TableColumn<Holiday>[] => [
  {
    name: 'Occasion',
    selector: 'title',
    sortable: true,
  },
  {
    name: 'Date',
    cell: (row): string => moment(new Date(row.date)).format('MMM Do, YYYY'),
    sortable: true,
  },
  {
    name: 'For',
    cell: (row): string => (row.forAll ? 'All' : row.sections.map((r) => r.name).join(',')),
  },
];

const UpcomingHolidaysWidget = (): JSX.Element => {
  const { fetchUpComingHolidays } = useHoliday();

  const upComingHoliday = useSelector((state: RootState) => state.upComingHoliday);

  const [activeHoliday, setActiveHoliday] = React.useState<Holiday | null>(null);

  React.useEffect(() => {
    fetchUpComingHolidays();
  }, []);

  const reload = (): void => {
    fetchUpComingHolidays();
  };

  const holidays = React.useMemo(() => {
    return upComingHoliday.data
      .map((a) => a)
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  }, [upComingHoliday.data]);

  return (
    <>
      <AbsentViewWrapper style={{ height: 'auto', marginLeft: 10 }}>
        <div className="title">
          Up Coming Holidays
          <Button
            onClick={reload}
            type="link"
            disabled={upComingHoliday.status === 'loading'}
            icon={<ReloadOutlined />}
          />
        </div>
        <Divider style={{ marginTop: 'var(--gap)', marginBottom: 'var(--gap)' }} />

        <DataTable
          noHeader
          dense
          highlightOnHover
          responsive
          progressPending={upComingHoliday.status === 'loading'}
          progressComponent={<KSpinner />}
          data={holidays}
          columns={upcomingHolidaysTableModel()}
          onRowClicked={(ev): void => setActiveHoliday(ev)}
        />
      </AbsentViewWrapper>

      <Modal
        title="Holiday Details"
        className="event-details-modal"
        visible={!!activeHoliday}
        centered
        closable
        maskClosable
        destroyOnClose
        footer={false}
        onCancel={(): void => {
          setActiveHoliday(null);
        }}
      >
        {activeHoliday && (
          <>
            <Card title={activeHoliday.title}>
              <p>{activeHoliday.description}</p>

              <br />

              <Card.Meta
                title={`${moment(new Date(activeHoliday.date)).format('DD MMM YYYY')}, ${moment(
                  new Date(activeHoliday.date),
                ).fromNow()}`}
              />
            </Card>
          </>
        )}
      </Modal>
    </>
  );
};

export default UpcomingHolidaysWidget;
